<template>
    <v-container fluid>
        <v-card :loading="loading">
            <Page-Header :title="$t('policy.policy')" icon="mdi-text-account" />
            <v-card-text>
                <tiptap-vuetify
                    v-model="policy.text"
                    :card-props="{
                        outlined: true
                    }"
                    :extensions="extensions"
                    :toolbar-attributes="{
                        color: $vuetify.theme.dark ? '' : '#EEEEEE'
                    }"
                />
            </v-card-text>
            <v-card-actions>
                <v-btn
                    v-if="isInRole(roles.policy.update)"
                    color="primary darken-1"
                    class="mx-2 my-1 white--text"
                    @click="save"
                >
                    {{ $t("save") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import PageHeader from "../../components/PageHeader.vue";
import {
    TiptapVuetify,
    Heading,
    Bold,
    Italic,
    Strike,
    Underline,
    Code,
    Paragraph,
    BulletList,
    OrderedList,
    ListItem,
    Link,
    Blockquote,
    HardBreak,
    HorizontalRule,
    History
} from "tiptap-vuetify";

export default {
    components: { PageHeader, TiptapVuetify },
    data() {
        return {
            extensions: [
                History,
                Blockquote,
                Link,
                Underline,
                Strike,
                Italic,
                ListItem,
                BulletList,
                OrderedList,
                [
                    Heading,
                    {
                        options: {
                            levels: [1, 2, 3]
                        }
                    }
                ],
                Bold,
                Code,
                HorizontalRule,
                Paragraph,
                HardBreak
            ],
            loading: true,
            dialog: false,
            dialogDelete: false,
            editedIndex: -1,
            policy: {
                text: null
            },
            editedItem: {},
            defaultItem: {},
            rules: [value => !!value || this.$t("required")]
        };
    },
    created() {
        if (!this.isInRole(this.roles.policy.policy))
            this.redirectUnauthorizedUsers();
        this.loading = true;
        this.getPolicy();
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        }
    },
    methods: {
        getPolicy() {
            try {
                axios
                    .get("Policy/Get")
                    .then(response => {
                        if (response.data.data == null) return;
                        this.policy = response.data.data;
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                console.log(error);
            }
        },
        save() {
            try {
                this.loading = true;
                axios
                    .post("Policy/Save", { text: this.policy.text })
                    .then(response => {
                        if (response.data.status == this.responseStatus.ok) {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                        this.getPolicy();
                    });
            } catch (error) {
                console.log(error);
            }
        }
    }
};
</script>
